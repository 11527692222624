import React from "react";
import SendMessageForm from "./forms/SendMessageForm";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { MODALS } from "./utils/ConsultationModals";
import TelemedicineAbuseInfo from "./banner/TelemedicineAbuseInfo";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { eventName, features } from "../services/constants";
import PrescriptionDataV2 from "./boxes/PrescriptionDataV2";
import {
  ATTACHMENT_TYPE,
  CONSULTATION_STATUS,
} from "../services/utilityService";
import { logAnalyticsEvent } from "../firebaseInit";

const BUBBLE_HEIGHT_THRESHOLD = 100;

class MChatPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewMessageButton: false,
      showTelemedicineAbuseBanner: true,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
    const consultationId = this.getConsultationId();
    const bannerClosed = localStorage.getItem(`bannerClosed-${consultationId}`);
    if (bannerClosed) {
      this.setState({ showTelemedicineAbuseBanner: false });
    }
  }

  renderChatView = (message, index, arr, cb) => {
    if (index > 0) {
      let m1 = moment(arr[index - 1].created_at).startOf("day");
      let m2 = moment(message.created_at).startOf("day");
      let sameDay = m1.isSame(m2, "day");
      if (!sameDay) {
        return cb(message, true);
      } else {
        return cb(message, false);
      }
    } else {
      return cb(message, true);
    }
  };

  scrollToBottom = (cb) => {
    this.messagesEnd.scrollIntoView({
      behaviour: "smooth",
    });
    if (cb) {
      cb();
    }
  };

  shouldShowNewMessageButton = () => {
    const chatLayout = document.getElementsByClassName("chat-section")[0];

    if (
      chatLayout &&
      chatLayout.scrollTop +
        chatLayout.offsetHeight +
        BUBBLE_HEIGHT_THRESHOLD * 2 <
        chatLayout.scrollHeight
    ) {
      this.toggleNewMessageButton(true);
    } else {
      this.scrollToBottom();
    }
  };

  toggleNewMessageButton = (showNewMessageButton) => {
    this.setState({
      showNewMessageButton,
    });
  };

  getConsultationId = () => {
    const urlSegments = window.location.pathname.split("/");
    return urlSegments[urlSegments.length - 2];
  };

  closeBanner = () => {
    const consultationId = this.getConsultationId();
    localStorage.setItem(`bannerClosed-${consultationId}`, "true");
    this.setState({ showTelemedicineAbuseBanner: false });
  };

  render() {
    const { showNewMessageButton } = this.state;
    const {
      messages,
      renderChat,
      onSubmitMessage,
      data,
      consultationStatus,
      setMessageInputRef,
      prescriptions,
      attachments,
      state,
      togglePharmacyModal,
    } = this.props;

    const shouldShowPrescriptionData =
      isFeatureEnabled(features.prescriptionFulfillment) &&
      prescriptions &&
      attachments.length > 0 &&
      state !== "deleted" &&
      consultationStatus !== CONSULTATION_STATUS.pending &&
      attachments[0].type === ATTACHMENT_TYPE.prescription;

    const isTelemedicineBannerEnabled = isFeatureEnabled(
      features.telemedicineAbuseBanner
    );
    const isBannerVisible = this.state.showTelemedicineAbuseBanner;
    const isConsultationActiveOrReopened =
      consultationStatus === CONSULTATION_STATUS.active ||
      consultationStatus === CONSULTATION_STATUS.reopened;

    const shouldShowTelemedicineAbuseInfo =
      isTelemedicineBannerEnabled &&
      isBannerVisible &&
      isConsultationActiveOrReopened;

    return (
      <div
        className="chat-wrapper dashboard-card"
        ref={(el) => {
          this.messagesEnd = el;
          this.chatLayoutRef = el;
        }}
      >
        {shouldShowTelemedicineAbuseInfo && (
          <TelemedicineAbuseInfo
            showTelemedicineAbuseModal={() => {
              const { enrollee_id, consultation_id, prescription_limit } =
                this.props;
              logAnalyticsEvent(eventName.CLICK_PRESCRIPTION_BANNER_CTA, {
                enrollee_id,
                consultation_id,
                prescription_limit,
              });
              togglePharmacyModal(MODALS.telemedicineAbuse);
            }}
            closeBanner={this.closeBanner}
          />
        )}
        <div className="chat-section">
          {messages && messages.length > 0 ? (
            messages.map((message, index, arr) =>
              this.renderChatView(message, index, arr, renderChat)
            )
          ) : (
            <h1
              style={{
                textAlign: "center",
                width: "100%",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#c5c4c4",
              }}
            >
              {this.props.t("no_messages_message")}
            </h1>
          )}
          <div style={{ visibility: "hidden" }} ref={this.props.chatLayoutRef}>
            *
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {showNewMessageButton && (
            <div
              className="feedback_scroll_btn"
              style={{ fontSize: "12px", borderRadius: "1rem" }}
              onClick={() =>
                this.scrollToBottom(this.toggleNewMessageButton(false))
              }
            >
              {this.props.t("new_message")}
            </div>
          )}
          {shouldShowPrescriptionData && (
            <PrescriptionDataV2
              {...this.props}
              message={this.props.prescriptions?.message}
              showFufillmentModal={() =>
                togglePharmacyModal(MODALS.consultationFulfillment)
              }
            />
          )}
          <SendMessageForm
            onSubmit={onSubmitMessage}
            data={data}
            consultationStatus={consultationStatus}
            setMessageInputRef={setMessageInputRef}
          />
        </div>
      </div>
    );
  }
}

const MChatPageWithTranslation = withTranslation(["consultDoctor"], {
  withRef: true,
})(MChatPage);

const mapStateToProps = (store) => {
  const {
    consultation,
    session: { user },
  } = store;

  if (consultation)
    return {
      enrollee_id: user?.hmoId,
      consultation_id: consultation?.active?.id,
      prescription_limit: consultation?.active?.dispensingLimit?.limit,
    };

  if (consultation.prescriptions)
    return {
      prescriptions: consultation.prescriptions,
      ...consultation.prescriptions.prescriptionMessage,
    };
};

export default withRouter(connect(mapStateToProps)(MChatPageWithTranslation));
