import React from "react";
import moment from "moment";
import SelectPharmacyModal from "../modals/SelectPharmacyModal";
import { useTranslation } from "react-i18next";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { features } from "../../services/constants";

const status = {
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
  DISPATCHED: "DISPATCHED",
  RECEIVED: "RECEIVED",
  CONFIRMED: "CONFIRMED",
  READY_FOR_PICKUP: "READY_FOR_PICKUP",
  PICKED_UP: "PICKED_UP",
  DELIVERED: "DELIVERED",
  DEACTIVATED: "DEACTIVATED",
};

const isActive = (dispatch_status) => {
  return dispatch_status !== "deactivated" || dispatch_status !== "cancelled";
};

const renderTick = (dispatch_status) => {
  if (dispatch_status) {
    switch (dispatch_status.toUpperCase()) {
      case status.DELIVERED:
      case status.DISPATCHED:
      case status.READY_FOR_PICKUP:
      case status.RECEIVED:
      case status.PICKED_UP:
        return (
          <i
            className="far fa-check-circle"
            style={{
              color: "#09A55A",
              width: "16.29px",
              height: "16.29px",
            }}
          ></i>
        );
      case status.DEACTIVATED:
        return (
          <i
            class="fa fa-ban"
            style={{
              color: "#ed0735",
              width: "16.29px",
              height: "16.29px",
            }}
          ></i>
        );
      default:
        return (
          <i
            className="far fa-clock"
            style={{
              color: "#FCB000",
              width: "16.29px",
              height: "16.29px",
            }}
          ></i>
        );
    }
  } else {
    return null;
  }
};

const pickUpMessages = {
  [status.PENDING]: "Pharmacy selected. Awaiting confirmation",
  [status.CONFIRMED]: "Pharmacy selected. Prescription confirmed",
  [status.READY_FOR_PICKUP]: "Pharmacy selected. Prescription ready for pickup",
  [status.PICKED_UP]: "Pharmacy selected. Prescription picked up",
  [status.DEACTIVATED]: "Prescription deactivated",
};

const deliveryMessages = {
  [status.PENDING]: "Delivery selected. Awaiting confirmation",
  [status.DISPATCHED]: "Delivery selected. Out for delivery",
  [status.RECEIVED]: "Delivery selected. Prescription received",
  [status.CONFIRMED]: "Delivery selected. Prescription confirmed",
  [status.DELIVERED]: "Delivery selected. Prescription delivered",
  [status.DEACTIVATED]: "Prescription deactivated",
};

const dispatchMessage = (dispatchStatus, fulfilmentMethod) => {
  const method = fulfilmentMethod?.toLowerCase();

  if (method === "pickup") {
    return pickUpMessages[dispatchStatus] || "Status Unknown";
  }

  if (method === "delivery") {
    return deliveryMessages[dispatchStatus] || "Status Unknown";
  }
};

const PrescriptionData = ({
  prescriptions,
  MESSAGE_TYPE,
  ATTACHMENT_TYPE,
  message,
  created_at,
  type,
  attachments,
  showFufillmentModal,
  showPharmacyModal,
  togglePharmacyModal,
  pharmacyModalName,
  userToken,
  pharmacies,
  consultationId,
}) => {
  const { i18n } = useTranslation(["webapp"]);

  // Helper Functions
  const isPrescriptionFulfilled = prescriptions?.dispatch_status;
  const isActionMessageWithPrescription =
    prescriptions &&
    prescriptions.delivery_option &&
    type === MESSAGE_TYPE.action &&
    attachments?.[0]?.type === ATTACHMENT_TYPE.prescription;

  const renderFulfillmentMessage = () => (
    <div className="doctor-chat__bubble prescription-chat-v2">
      <div className="prescription-content">
        <div
          data-tooltip="Click here to select a preferred Pharmacy"
          className="prescription-icon"
        >
          <i className="fas fa-prescription-bottle-alt"></i>
        </div>
        <div className="prescription-text">
          <p>
            {message}.{" "}
            {isActive(prescriptions?.dispatch_status) && (
              <em
                onClick={(e) => {
                  showFufillmentModal();
                }}
                className="view-link"
              >
                Click here to view
              </em>
            )}
          </p>
        </div>
      </div>
      <p>{moment(created_at).format("HH:mm")}</p>
    </div>
  );

  const renderActionMessage = () => (
    <div
      className="dispatch-message"
      style={{ textAlign: "left", paddingLeft: "25px" }}
    >
      {renderTick(prescriptions?.dispatch_status)}
      {dispatchMessage(
        prescriptions?.dispatch_status,
        prescriptions?.delivery_option
      )}
    </div>
  );

  const renderPharmacyModal = () => (
    <SelectPharmacyModal
      onClose={togglePharmacyModal}
      userToken={userToken}
      pharmacies={pharmacies}
      consultationId={consultationId}
      prescriptionId={attachments?.[0]?.content?.id}
    />
  );

  if (isFeatureEnabled(features.prescriptionFulfillment)) {
    return (
      <div className="prescription-container">
        {isPrescriptionFulfilled && renderFulfillmentMessage()}
        {isActionMessageWithPrescription && renderActionMessage()}
      </div>
    );
  }

  return (
    <>
      {showPharmacyModal && renderPharmacyModal()}
      <div
        className="pharmacy-info"
        style={{
          display: "flex",
          gap: "4px",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
        onClick={() => togglePharmacyModal(pharmacyModalName)}
      >
        <div
          data-tooltip="Click here to select a preferred Pharmacy"
          className="pharmacy-icon"
        >
          <i className="fas fa-prescription-bottle-alt"></i>
        </div>
        <div className="pharmacy-text">
          <p>{message}</p>
          <p>{moment(created_at).locale(i18n.language).format("HH:mm")}</p>
        </div>
      </div>
    </>
  );
};

export default PrescriptionData;
