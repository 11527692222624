import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { change, arrayRemoveAll } from "redux-form";
import BubbleLeft from "../components/boxes/BubbleLeft";
import BubbleRight from "../components/boxes/BubbleRight";
import MChatPage from "../components/MChatPage";
import LoadingScreen from "../components/LoadingScreen";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import moment from "moment";
import ActiveModal, { MODALS } from "../components/utils/ConsultationModals";
import DoctorInfoBanner from "../components/boxes/DoctorInfoBanner";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import UtilityService, {
  ATTACHMENT_TYPE,
  CONSULTATION_LIMIT,
  CONSULTATION_STATUS,
} from "../services/utilityService";
import {
  clearNewMessage,
  clearEndConfirmation,
  updatePrescriptionId,
  populateActiveConsultation,
} from "../actions/consultation";
import { useTranslation, withTranslation } from "react-i18next";
import CallStatus from "../components/CallStatus";
import missedCall from "../img/phone-missed.svg";
import phoneOff from "../img/phone-off.svg";
import phone from "../img/phone.svg";
import FulfillmentModal from "../components/modals/fulfillment";
import DeletedMessage from "../components/DeletedMessage";

const intervalMilliseconds = 5000;

class ConsultationDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(
      props.user.credentials.accessToken,
      props.i18n.language
    );
    this.v2Service = new ApiService(
      props.user.credentials.accessToken,
      "",
      process.env.REACT_APP_BASE_API_URL + "/v2"
    );

    this.state = {
      messages: [],
      isLoading: false,
      isSending: false,
      error: null,
      consultation: null,
      selectedDoctor: null,
      pharmacies: [],
      lgas: [],
      attachments: [],
      doctorOnlineStatus: null,
      activeModal: null,
    };
    this.chatLayoutRef = React.createRef();
    this.messageInputRef = null;
    this.fetchMessageInterval = null;
  }

  componentDidMount() {
    //invalidate first...otherwise, messages wont fetch
    this.fetchMessages().then(() => {
      if (
        this.state.consultation.consultation_status ===
          CONSULTATION_STATUS.active ||
        this.state.consultation.consultation_status ===
          CONSULTATION_STATUS.reopened ||
        this.state.consultation.consultation_status ===
          CONSULTATION_STATUS.closePending
      ) {
        this.fetchMessageInterval = setInterval(
          this.fetchMessages,
          intervalMilliseconds
        );
      }
    });
    this.getPharmacies();
    this.getLgas();
  }

  componentWillUnmount() {
    clearInterval(this.fetchMessageInterval);
  }

  componentDidUpdate(prevProps) {
    const {
      newMessages,
      endConsultationConfirmations,
      openedConsultationStatus,
    } = this.props;
    if (prevProps.newMessages !== newMessages) {
      this.displayNewConsultationMsgs(newMessages);
    }
    if (
      prevProps.endConsultationConfirmations !== endConsultationConfirmations
    ) {
      this.handleEndConsultationModal(endConsultationConfirmations);
    }
    if (
      prevProps.openedConsultationStatus !== openedConsultationStatus &&
      openedConsultationStatus.consultation_id === this.state.consultation.id
    ) {
      this.setConsultationStatus(openedConsultationStatus.status_id);
    }
  }

  fetchMessages = () => {
    const { match } = this.props;
    const queryData = { limit: CONSULTATION_LIMIT };

    if (!this.state.consultation) this.setLoading(true);
    return this.service
      .get(ENDPOINTS.consultations.consultationDetails(match.params.id))
      .then((response) => response.data)
      .then((consultation) => {
        return this.service
          .get(ENDPOINTS.consultations.messages(consultation.id), queryData)
          .then((r) => {
            const isClosePending =
              parseInt(consultation.consultation_status) ===
              CONSULTATION_STATUS.closePending;
            const endModal = isClosePending
              ? { activeModal: MODALS.endConsultation }
              : null;
            this.setState(
              {
                isLoading: false,
                consultation,
                ...endModal,
                messages: r.data.reverse(),
              },
              () => {
                if (
                  this.state.consultation.consultation_status !==
                    CONSULTATION_STATUS.active &&
                  this.state.consultation.consultation_status !==
                    CONSULTATION_STATUS.reopened &&
                  this.state.consultation.consultation_status !==
                    CONSULTATION_STATUS.closePending &&
                  this.fetchMessageInterval
                ) {
                  clearInterval(this.fetchMessageInterval);
                }
                this.props.dispatch(populateActiveConsultation(consultation));
              }
            );
          });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  handleData = (data, msgId) => {
    let message = JSON.parse(data);
    if (typeof message === "string") {
      message = JSON.parse(message);
    }
    const { messages } = this.state;
    if (messages) {
      let consultationMsgs = [...messages];
      if (message.id && !msgId) {
        consultationMsgs.push(message);
      } else {
        consultationMsgs = messages.map((msg) => {
          if (msg.id === msgId) {
            return { ...msg, ...message };
          }
          return msg;
        });
      }
      this.setState(
        {
          messages: consultationMsgs,
        },
        () => {
          if (message.sender_id === this.state.consultation.patient.id) {
            // this.chatLayoutRef.current.scrollToBottom();
          } else {
            // this.chatLayoutRef.current.shouldShowNewMessageButton();
          }
        }
      );
    }
  };

  displayNewConsultationMsgs(newMsgs) {
    const { dispatch } = this.props;
    let newConsultationMsgs = [];
    Object.keys(newMsgs).forEach((msgKey) => {
      if (
        newMsgs[msgKey].consultation_id === this.state.consultation.id &&
        newMsgs[msgKey].display
      ) {
        newConsultationMsgs.push(newMsgs[msgKey]);
      }
    });
    if (newConsultationMsgs.length > 0) {
      this.handleData(JSON.stringify(newConsultationMsgs[0]));
      dispatch(clearNewMessage(this.state.consultation.id));
    }
  }

  setConsultationStatus(newStatusId) {
    this.setState({
      consultation: {
        ...this.state.consultation,
        consultation_status: newStatusId,
      },
    });
  }

  handleEndConsultationModal(endConsultationConfirmations) {
    const { consultation } = this.state;
    if (endConsultationConfirmations[consultation?.id]) {
      this.displayModal(MODALS.endConsultation);
    }
  }

  updateConsultationStatus = (newStatus) => {
    const { dispatch, history } = this.props;
    const data = { status: newStatus };

    this.setLoading(true);
    this.service
      .put(ENDPOINTS.consultations.status(this.state.consultation.id), data)
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            activeModal: null,
            consultation: {
              ...this.state.consultation,
              consultation_status: response.data.status,
            },
          },
          () => {
            dispatch(clearEndConfirmation(this.state.consultation.id));
            if (
              response.data.status === CONSULTATION_STATUS.closed &&
              this.state.consultation.rating === 0
            ) {
              history.push(
                `/consultations/${this.state.consultation.id}/rate-consultation`
              );
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  setSending = (isSending) => {
    this.setState({
      isSending,
    });
  };

  setError = (error) => {
    this.setState({
      error,
    });
  };

  getPharmacies = () => {
    this.service
      .get(ENDPOINTS.pharmacies.allPharmacies)
      .then((response) => {
        const pharmaciesWithValidStateObject = response.data.filter(
          (pharmacy) => {
            if (pharmacy?.state_object) {
              return pharmacy;
            }
          }
        );
        this.setState({
          pharmacies: pharmaciesWithValidStateObject,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getLgas = () => {
    this.v2Service
      .get(ENDPOINTS.consultations.lgas)
      .then((response) => {
        this.setState({
          lgas: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkDoctorOnlineStatus = () => {
    const { consultation } = this.state;

    this.service
      .get(ENDPOINTS.doctor.retrieveStatus(consultation.doctor.id))
      .then((response) => {
        this.setState({
          doctorOnlineStatus: response.data.online_status,
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
        console.log(error);
      });
  };

  setMessageInputRef = (ref) => {
    this.messageInputRef = ref;
  };

  displayModal = (modalName, extras, cb) => {
    if (cb) cb();
    this.setState({
      activeModal: modalName || null,
      ...extras,
    });
  };

  onSubmitMessage = (values) => {
    const {
      user: {
        credentials: { accessToken },
      },
    } = this.props;

    if (values.question.length > 0 && !values.fileArray) {
      const currentTime = new Date();
      const timeSent = moment(
        !values.resend ? currentTime : values.timeSent
      ).format("HH:mm:ss");
      if (!values.resend) {
        let newMessage = {
          id: timeSent,
          message: values.question,
          type: 1,
          sender_id: this.props.user.id,
          created_at: currentTime,
          status: "sending",
        };
        this.handleData(JSON.stringify(newMessage));
        this.props.dispatch(change("sendMessageForm", "question", ""));
        this.messageInputRef.focus();
      } else {
        let resent = {
          status: "sending",
        };
        this.handleData(JSON.stringify(resent), timeSent);
      }
      this.service
        .post(
          ENDPOINTS.consultations.messages(
            values.consultationId || this.state.consultation.id
          ),
          { message: values.question, type: "1" }
        )
        .then((response) => {
          let sentMessage = {
            id: response.data.consultation_message_id,
            status: "send_success",
          };
          this.handleData(JSON.stringify(sentMessage), timeSent);
        })
        .catch(() => {
          let resent = {
            status: "send_failed",
          };
          this.handleData(JSON.stringify(resent), timeSent);
        });
    }

    if (values.question.length > 0 && values.fileArray) {
      this.setSending(true);

      const files = values.fileArray.map((file) =>
        UtilityService.uploadFile(file, "consultation_media", accessToken)
      );

      Promise.all(files)
        .then((response) => {
          return Promise.resolve({
            files: response,
            message: {
              message: values.question,
              type: ATTACHMENT_TYPE.media,
              attachments: response.map((file) => ({
                id: file.file_id,
                type: ATTACHMENT_TYPE.media,
              })),
            },
          });
        })
        .then((data) => {
          return this.service
            .post(
              ENDPOINTS.consultations.messages(values.consultationId),
              data.message
            )
            .then((response) => {
              let newMessage = {
                id: response.data.consultation_message_id,
                message: values.question,
                attachments: data.files.map((file, index) => ({
                  id: index + 1,
                  type: ATTACHMENT_TYPE.media,
                  content: {
                    id: file.file_id,
                    url: file.file_url,
                  },
                })),
                type: 1,
                sender_id: this.props.user.id,
                created_at: new Date(),
              };

              this.setSending(false);
              this.handleData(JSON.stringify(newMessage));
              this.props.dispatch(change("sendMessageForm", "question", ""));
              this.props.dispatch(
                arrayRemoveAll("sendMessageForm", "fileArray")
              );
            });
        });
    }
  };

  renderLoading = () => {
    return (
      <MainContent>
        <LoadingScreen />
      </MainContent>
    );
  };

  formatTime = (time) => {
    moment.locale(this.props.i18n.language);
    return moment(time).format("hh:mm A");
  };

  renderMessage = (message) => {
    const {
      user,
      match: { params },
      dispatch,
    } = this.props;
    if (message.sender_id === user.id) {
      return (
        <BubbleRight
          key={message.id}
          {...message}
          resendMessage={this.onSubmitMessage}
          userToken={user.credentials.accessToken}
          nextModal={MODALS.attachment}
          onViewAttachments={this.displayModal}
        />
      );
    } else {
      message.state === "active" &&
        message.attachments[0]?.content?.id &&
        dispatch(updatePrescriptionId(message.attachments[0]?.content.id));

      return (
        <BubbleLeft
          key={message.id}
          prescriptionMessage={message}
          {...message}
          userToken={user.credentials.accessToken}
          service={this.service}
          pharmacyModalName={MODALS.pharmacy}
          togglePharmacyModal={this.displayModal}
          referralModalName={MODALS.referral}
          toggleReferralModal={this.displayModal}
          pharmacies={this.state.pharmacies}
          showPharmacyModal={this.state.activeModal === MODALS.pharmacy}
          showReferralModal={this.state.activeModal === MODALS.referral}
          consultationId={params.id}
          activeModal={this.state.activeModal}
        />
      );
    }
  };

  renderChat = (message, renderDate) => {
    const { type, message: callMessage } = message;
    if ([2, 4, 5].includes(type)) {
      return (
        <CallStatus
          img={type === 4 ? missedCall : type === 3 ? phoneOff : phone}
          language={this.props.i18n.language}
          message={
            type === 4
              ? `${callMessage} at ${this.formatTime(message.created_at)}`
              : type === 5
              ? `${callMessage} at ${this.formatTime(message.created_at)}`
              : type === 2
              ? `${callMessage} at ${this.formatTime(message.created_at)}`
              : ""
          }
          key={"with_date_" + message.id}
          // footer={ message.message}
        />
      );
    }
    if (renderDate) {
      return (
        <React.Fragment key={"with_date_" + message.id}>
          {this.renderMessageDate(message.created_at)}
          {this.renderMessage(message)}
        </React.Fragment>
      );
    } else {
      return this.renderMessage(message);
    }
  };

  renderMessageDate = (date) => {
    return (
      <div className="chat-item__date" key={"date_" + date.toString()}>
        <p>{`    ${moment(date)
          .locale(this.props.i18n.language)
          .format("dddd, MMMM Do YYYY")}   `}</p>
      </div>
    );
  };

  render() {
    const {
      messages,
      isLoading,
      isSending,
      error,
      consultation,
      attachments,
      selectedDoctor,
      activeModal,
      doctorOnlineStatus,
    } = this.state;

    const {
      user: {
        credentials: { accessToken },
      },
      history,
    } = this.props;

    if (isLoading || !consultation) return this.renderLoading();
    return (
      <MainContent>
        <PageHeader
          title={this.props.t("consult_doctor.how_options_chat")}
          isSearchable={false}
        />
        <PageBody customClass="column">
          {(isSending || error) && (
            <div className={`page-info-banner${error ? " error" : ""}`}>
              {isSending && <h3>Sending...</h3>}
              {error && <h3>{error.message}</h3>}
            </div>
          )}
          {activeModal === MODALS.consultationFulfillment && (
            <FulfillmentModal
              isOpen={this.state.activeModal === MODALS.consultationFulfillment}
              onClose={this.displayModal}
              pharmacies={this.state.pharmacies}
              lgas={this.state.lgas}
              service={this.service}
              userToken={this.state.userToken}
              consultationId={this.props.match.params.id}
              {...this.state.messages}
            />
          )}
          {!(isSending || error) && consultation.doctor && (
            <DoctorInfoBanner
              doctor={consultation.doctor}
              nextModal={MODALS.selectedDoctorProfile}
              onClick={this.displayModal}
              history={history}
              consultationStatus={consultation.consultation_status}
              toggleFollowUpConsultationModal={() =>
                this.displayModal(
                  MODALS.followUpConsultation,
                  null,
                  this.checkDoctorOnlineStatus()
                )
              }
            />
          )}
          {activeModal !== null && (
            <ActiveModal
              activeModal={activeModal}
              consultation={consultation}
              displayModal={this.displayModal}
              accessToken={accessToken}
              doctorOnlineStatus={doctorOnlineStatus}
              checkDoctorOnlineStatus={this.checkDoctorOnlineStatus}
              attachments={attachments}
              selectedDoctor={selectedDoctor}
              updateConsultationStatus={this.updateConsultationStatus}
            />
          )}
          <MChatPage
            ref={this.chatLayoutRef}
            setMessageInputRef={this.setMessageInputRef}
            messages={messages}
            renderChat={this.renderChat}
            onSubmitMessage={this.onSubmitMessage}
            togglePharmacyModal={this.displayModal}
            toggleModal={this.displayModal}
            isSending={isSending}
            error={error}
            chatLayoutRef={this.chatLayoutRef}
            data={{
              consultation,
              consultationId: consultation.id,
              userToken: accessToken,
            }}
            consultationStatus={consultation.consultation_status}
          />
        </PageBody>
      </MainContent>
    );
  }
}

const mapStateToProps = ({ consultation }) => ({
  newMessages: consultation.newMessages,
  endConsultationConfirmations: {
    ...consultation.endConsultationConfirmations,
  },
  openedConsultationStatus: consultation.openedConsultationStatus,
});

const ConsultationDetailContainerWithTranslation = withTranslation([
  "webapp",
  "consultDoctor",
])(ConsultationDetailContainer);

export default withRouter(
  connect(mapStateToProps)(ConsultationDetailContainerWithTranslation)
);
