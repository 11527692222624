import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Info from "../../img/Info.svg";

const DISPENSE_LIMIT = {
  aboveLimit:
    "Based on your prescription history, we are unable to safely issue additional prescriptions via this telemedicine platform at this time.",
  belowLimit:
    "For your safety and optimal health, prescriptions are provided only when they are deemed medically necessary by the prescribing doctor.",
};

const CONTENT = {
  learnMore: "Learn more",
};

const TelemedicineAbuseInfo = ({
  activeConsultation,
  showTelemedicineAbuseModal,
  closeBanner,
}) => {
  const { dispensingLimit } = activeConsultation;
  if (dispensingLimit) {
    return (
      <div
        style={{
          position: "fixed",
          top: 150,
          backgroundColor: "#FFFAF2",
          width: "75vw",
          fontSize: 16,
          padding: 10,
          color: "#323539",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 30 }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <div>
              <img src={Info} alt="Info" />
            </div>
            <div>
              {dispensingLimit.count < dispensingLimit.limit
                ? DISPENSE_LIMIT.belowLimit
                : DISPENSE_LIMIT.aboveLimit}
              <p
                onClick={showTelemedicineAbuseModal}
                style={{
                  cursor: "pointer",
                  color: "#094063",
                }}
              >
                {CONTENT.learnMore}
              </p>
            </div>
          </div>
          <div
            style={{ fontSize: 16, fontWeight: "bolder", cursor: "pointer" }}
            onClick={closeBanner}
          >
            X
          </div>
        </div>
        <div></div>
      </div>
    );
  } else return null;
};

const mapStateToProps = (store) => {
  const { consultation } = store;
  return {
    prescriptions: consultation?.prescriptions,
    activeConsultation: consultation?.active || {},
  };
};

export default withRouter(connect(mapStateToProps)(TelemedicineAbuseInfo));
