import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Heading, Modal, Button } from "@reliance/design-system";
import DangerIcon from "../../img/DangerInfo.svg";

const CONTENT = {
  modalHeader: "Prescription Safety",
  belowLimit: {
    intro:
      "To protect your health and prevent you from medication side effects, please note:",
    notes: [
      "You can consult a doctor anytime, but the doctor will decide if a prescription or referral is necessary.",
      "As a safety measure, the number of prescriptions available to you is limited within specific periods.",
      "You are not able to request specific brands of medications.",
    ],
    footer:
      "If you still need help after consultation, visit a hospital for additional care or contact our support team with any questions.",
  },
  aboveLimit: {
    intro:
      "Based on your prescription history, your doctor cannot provide you with a prescription at this time. This is to protect your health and prevent the risks associated with overprescribing. Remember:",
    notes: [
      "You can consult a doctor anytime, but the doctor will decide if a prescription or referral is necessary.",
      "As a safety measure, the number of prescriptions available to you is limited within specific periods.",
      "If you require additional care, we recommend that you visit a hospital.",
    ],
    footer: "You can contact our support team if you have any questions.",
  },
};

const TelemedicineAbuseModal = ({ open, onClose, dispensingLimit }) => {
  // const isDispenseBelowLimit = dispensingLimit.limit >= dispensingLimit.count;

  const { intro, notes, footer } =
    dispensingLimit.count < dispensingLimit.limit
      ? CONTENT.belowLimit
      : CONTENT.aboveLimit;

  return (
    <Modal key="modal" open={open} onOpenChange={onClose}>
      <Modal.Content
        blank
        style={{ fontSize: 16 }}
        className="w-[40vw] lg:w-[38vw] sm:w-auto max-w-[80%]"
      >
        <div className="text-center">
          <div className="flex justify-center items-center h-full">
            <img src={DangerIcon} alt="Info" />
          </div>
          <Heading level="h3" className="m-5" style={{ color: "#1E293B" }}>
            {CONTENT.modalHeader}
          </Heading>
        </div>
        <div className="mb-4">{intro}</div>
        {notes.length > 0 && (
          <ol className="list-disc pl-5 telemedicine-abuse">
            {notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ol>
        )}
        <div className="mt-10 mb-12">{footer}</div>
        <Button
          style={{ width: "100%", fontSize: 15, height: 50, margin: "20px 0" }}
          className="border-0 focus:border-0 hover:border-0"
          onClick={onClose}
        >
          Alright, got it
        </Button>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = ({ consultation }) => ({
  prescriptions: consultation?.prescriptions,
  dispensingLimit: consultation?.active?.dispensingLimit || {},
});

export default withRouter(connect(mapStateToProps)(TelemedicineAbuseModal));
