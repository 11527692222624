import React, { useTransition } from "react";
import PropTypes from "prop-types";
import Modal from "../boxes/Modal";
import { useTranslation, withTranslation } from "react-i18next";

function DoctorProfileModal({ doctor, onClose }) {
  const { t } = useTranslation(["consultDoctor"]);

  return (
    <Modal title={t("doctors_profile")} onClose={onClose}>
      <React.Fragment>
        <div
          className="dashboard-btn-section"
          style={{ justifyContent: "center" }}
        ></div>
        <div className="dashboard-card profile-section">
          <img
            className="profile__img"
            src={doctor.profile_picture}
            alt={doctor.last_name}
          />

          <div className="profile-detail-wrap">
            <div className="profile-item">
              <div className="profile-item-wrapper">
                <p className="profile-item__label">{t("name")}</p>
                <p className="profile-item__text"> {doctor.last_name}</p>
              </div>
              <div className="profile-item-wrapper">
                <p className="profile-item__label">{t("specialty")}</p>
                <p className="profile-item__text">{doctor.specialty}</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Modal>
  );
}

DoctorProfileModal.propTypes = {
  doctor: PropTypes.object,
  onClose: PropTypes.func,
};

const DoctorProfileModalWithTranslations = withTranslation(["consultDoctor"])(
  DoctorProfileModal
);
export default DoctorProfileModalWithTranslations;
