import React from "react";
import { CONSULTATION_STATUS } from "../../services/utilityService";
import { useTranslation, withTranslation } from "react-i18next";

function DoctorInfoBanner({
  doctor,
  nextModal,
  onClick,
  consultationStatus,
  toggleFollowUpConsultationModal,
}) {
  const { t } = useTranslation(["consultDoctor"]);
  if (doctor) {
    return (
      <div className="chat-doctor-wrapper">
        <div className="chat-doctor-info">
          <img
            className="chat-doctor__img"
            src={doctor.profile_picture || "https://via.placeholder.com/150"}
            alt={`Dr. ${doctor.lastName}\``}
          />
          <div style={{ marginLeft: "2rem" }}>
            <p className="chat-doctor-name">{`Dr. ${doctor.last_name}`}</p>
            <p
              className="chat-doctor-prompt"
              onClick={() => onClick(nextModal, { selectedDoctor: doctor })}
            >
              <u>{t("click_to_view_doc_profile")}</u>
            </p>
          </div>
        </div>
        {consultationStatus === CONSULTATION_STATUS.closed &&
          doctor.active_status !== 6 && (
            <button
              className="dashboard__primary-btn"
              onClick={toggleFollowUpConsultationModal}
            >
              {t("follow_up_consultation")}
            </button>
          )}
      </div>
    );
  } else {
    return null;
  }
}

const DoctorInfoBannerWithTranslation = withTranslation(["consultDoctor"])(
  DoctorInfoBanner
);

export default DoctorInfoBannerWithTranslation;
